/**
 * @Description: 导航栏底部
 * @author Sira
 * @date 2022/9/30
*/
<template>
<div :class="classType">
  备案号：{{ this.$store.getters.GET_HOMESITE !=void 0 ? this.$store.getters.GET_HOMESITE.registration:'000000' }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;© 2022{{ this.$store.getters.GET_HOMESITE !=void 0 ? this.$store.getters.GET_HOMESITE.siteName:'香云寺'  }}
</div>
</template>

<script>

export default {
  name: "Footer",
  props:{
    classType:{
      type:String,
      default:'footer-bottom'
    }
  },
  watch:{
    classType(e){
      console.log(e)
    }
  }
}
</script>
<style scoped>
.footer-bottom{
  text-align: center;
  background-color: #95A3B0;
  padding: 5px;
  color: #fff;
  width: 100%;
    font-size: 16px;
}
.footer-bottom-login{
  text-align: center;
  background-color: rgba(149, 163, 176, 0);
  padding: 20px;
  color: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
    font-size: 16px;

}
</style>
