import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dict from './config/dict'
import { Pagination } from 'element-ui'
import { DatePicker } from 'element-ui'
import { Input } from 'element-ui'
import { Select } from 'element-ui'
import { Option } from 'element-ui'
import { Dialog  } from 'element-ui'
import { Button  } from 'element-ui'
import VCalendar from 'v-calendar';
import cv from "vue-calendar-ui";
Vue.use(cv)
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Pagination).use(DatePicker).use(Input).use(Select).use(Option).use(Dialog).use(Button);
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
Vue.prototype.$dict = dict
Vue.config.productionTip = false
import 'bootstrap';
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import '@/css/cms.main.scss'
// or get all of the named exports for further usage
import * as bootstrap from 'bootstrap';

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
