import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {},
        loginInfo: {},
        homeConfig: {registration:{}},
    },
    getters: {
        GET_LOGIN(state, val) {
            return JSON.parse(localStorage.getItem('loginInfo'))
        },
        GET_USERINFO(state, val) {
            return JSON.parse(localStorage.getItem('userInfo'))
        },
        GET_HOMESITE(state, val) {
            if(localStorage.getItem('homeSite')!=null) return JSON.parse(localStorage.getItem('homeSite')); else return void 0
        },
    },
    mutations: {
        SET_LOGIN: (state, val) => {
            state.loginInfo = val
            localStorage.setItem('loginInfo', JSON.stringify(val))
        },
        SET_USERINFO: (state, val) => {
            state.userInfo = val
            localStorage.setItem('userInfo', JSON.stringify(val))
        },
        SET_HOMESITE: (state, val) => {
            state.homeConfig = val
            localStorage.setItem('homeSite', JSON.stringify(val))
        },
        CLEAR_ALL: (state, val) => {
            localStorage.clear()
        },
    },
    actions: {},
    modules: {}
})
