/**
 * @Description: 字典配置
 * @author Sira
 * @date 2022/9/30
*/
export default{
    global:{
        name:'云翔寺',
        navbarList:[{
            label:'首页'
        },{
            label:'开卷有益'
        },{
            label:'资料下载'
        },{
            label:'法音宣流'
        },{
            label:'答疑解惑'
        },{
            label:'法务通知'
        },{
            label:'圣地名刹'
        },]
    }
}

