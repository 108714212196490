/**
* @Description: 通用导航栏
* @author Sira
* @date 2022/11/8
*/
<template>
    <div>
        <div class="nav-box navbar-box" id="nav-box">
            <div class="row g-0">
                <div class="col-0 col-md-2"></div>
                <div class="col-0 col-md-8">
                    <img src="../../../assets/logo.png" class="img-fluid" alt="logo">
                    <i class="bi bi-list-task icon-more" @click="listStatus = !listStatus"></i>
                    <div class="menu-list" v-if="listStatus">
                        <div class="border-style" :class="index == isActive?'isItem':'item'" v-for="(item,index) in navBar" :key="index"
                             @click="linkTo(index),isActive = index">{{ item.label }}
                        </div>
                        <div class="item">
                            <img @click="linkToMe(),isActive = 999" src="../../../assets/user.png" alt=""
                                 style="width: 30px;height: 30px;margin-left: 10px;position: relative;top: -1.5px">
                            <!--              <div style="background-color: orange;border-radius: 50%;width: 30px;height:30px;text-align: center;">-->
                            <!--                <i @click="linkToMe(),isActive = 999" class="bi bi-person-fill"></i>-->
                            <!--              </div>-->
                        </div>
                    </div>
                </div>
                <div class="col-0 col-md-2"></div>
            </div>
        </div>
        <!--    <div class="nav-box nav-box2" id="nav-box2" v-if="!label">-->
        <!--      <div class="row g-0">-->
        <!--        <div class="col-0 col-md-2"></div>-->
        <!--        <div class="col-0 col-md-8">-->
        <!--          <img src="../../../assets/logo.png" class="img-fluid" alt="logo">-->
        <!--          <i class="bi bi-list-task icon-more" @click="listStatus = !listStatus"></i>-->
        <!--          <div class="menu-list" v-if="listStatus">-->
        <!--            <div :class="index == isActive?'isItem':'item'" v-for="(item,index) in navBar" :key="index"-->
        <!--                 @click="linkTo(index),isActive = index">{{ item.label }}-->
        <!--            </div>-->
        <!--            <div class="item">-->
        <!--              <div style="background-color: orange;border-radius: 50%;width: 30px;height:30px;text-align: center;">-->
        <!--                <i @click="linkToMe(),isActive = 999" class="bi bi-person-fill"></i>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-0 col-md-2"></div>-->
        <!--      </div>-->
        <!--    </div>-->
        <div style="position: fixed;top: 0;width: 100%">
            <nav class="navbar navbar-expand-lg base-NavBar">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#"><img src="../../../assets/logo.png" class="img-fluid"
                                                          style="width: 100%"></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="bi bi-list-ul" style="color: #fff;font-size: 2rem"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div class="navbar-nav" style="color: #fff">
                            <div class="item" v-for="(item,index) in navBar" :key="index" @click="linkTo(index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        navBar: {
            default: [
                {label: '首页', value: '0', url: '/'},
                {label: '开卷有益', value: '1', url: '/kjyy/index'},
                {label: '资料下载', value: '2', url: ''},
                {label: '法音宣流', value: '3', url: '/fyxl/index'},
                {label: '答疑解惑', value: '4', url: ''},
                {label: '法务通知', value: '4', url: ''},
                {label: '圣地名刹', value: '5', url: ''}
            ]
        },
    },
    data() {
        return {
            listStatus: true,
            isActive: '',
            whireMenu: ['/sdmc/sdmcInfo', '/articleInfo/index'],
            label: false
        }
    },
    watch: {
        $route(to, from) {
            console.log(to)
            document.documentElement.scrollTop = -1
            document.body.scrollTop = -1
        }
    },
    created() {
        this.isActive = this.$route.query.index
        var obj = document.getElementById("nav-box");
    },
    mounted() {
        var obj = document.getElementById("nav-box");
        var obj2 = document.getElementsByClassName("border-style");

        let that = this
        document.onscroll = function () {
            var st = document.body.scrollTop || document.documentElement.scrollTop;
            try {
                if (st > 10 && that.$route.path !== '/articleInfo/index' && that.$route.path !== '/sdmc/sdmcInfo') {
                    obj.style.backgroundColor = "#233769";
                    obj.style.color = "#fff";
                    let obj2 = document.getElementsByClassName("border-style");
                    for (var i in obj2){
                        obj2[i].style.borderRight = "solid 1px #091739"
                    }
                    // style="border-right: solid 1px gainsboro"
                } else if (that.$route.path == '/articleInfo/index' || that.$route.path == '/sdmc/sdmcInfo' || that.$route.path == '/live/index'|| that.$route.path == '/serach/index') {
                    obj.style.backgroundColor = "#233769";
                    obj.style.color = "#fff";
                    obj.style.color = "#fff";

                } else {
                    obj.style.backgroundColor = "transparent"
                    obj.style.color = "#fff"
                    let obj2 = document.getElementsByClassName("border-style");
                    for (var i in obj2){
                        console.log(obj2)
                        obj2[i].style.borderRight = "none"
                    }

                }
            } catch (e) {
                // var st = document.body.scrollTop || document.documentElement.scrollTop;
                // console.log(st)
                // if(st>10){
                //     obj2.style.borderRight = "none";
                //     obj3.style.borderRight = "none";
                // }else{
                //     obj2.style.borderRight = "solid 1px gainsboro";
                //     obj3.style.borderRight = "solid 1px gainsboro";
                // }

            }
        }
    },
    methods: {
        status() {
            for (let i = 0; i < this.whireMenu.length; i++) {
                if (this.whireMenu[i] === this.$route.path) {
                    this.label = false
                } else {
                    this.label = true
                }
            }
        },
        linkToMe() {
            this.$router.push({path: '/me/index'})
        },
        linkTo(e) {

            switch (e) {
                case 0:
                    this.$router.push({path: '/'})
                    return;
                case 1:
                    this.$router.push({path: '/kjyy/index'})
                    return;
                case 2:
                    this.$router.push({path: '/material/index'})
                    return;
                case 3:
                    this.$router.push({path: '/fyxl/index'})
                    return;
                case 4:
                    this.$router.push({path: '/q&a/index'})
                    return;
                case 5:
                    this.$router.push({path: '/law/index'})
                    return;
                case 6:
                    this.$router.push({path: '/sdmc/index'})
                    return;
                    document.documentElement.scrollTop = -1
                    document.body.scrollTop = -1
            }
        }
    }
}
</script>

<style scoped lang="scss">
.nav-box {
  //background-color: $baseBackColor;
  padding: 0.5rem;
  color: $baseColor;
  position: fixed;
  width: 100%;
  top: 0;
  color: #fff;
  z-index: 9999999999;
}

.nav-box2 {
  background-color: $baseBackColor;
  padding: 0.5rem;
  color: $baseColor;
  position: fixed;
  width: 100%;
  top: 0;
  color: #fff;
  z-index: 9999999999;
}

.menu-list {
  float: right;
}

.item {
  display: inline-block;
  padding: 1rem;
  cursor: pointer;
}

.isItem {
  display: inline-block;
  padding: 1rem;
  background-color: rgba(255, 165, 0);
  //border-radius: 10px;
  cursor: pointer;

}

.icon-more {
  display: none;
}

.base-NavBar {
  padding: 0.5rem;
  background-color: $baseBackColor;
  color: #fff;
}

@media screen and (max-width: 766px) {
  .icon-more {
    display: inline-block;
    font-size: 2rem;
    float: right;
  }
  .item {
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: center;
  }
  .menu-list {
    float: none;
  }
  .navbar-box {
    display: none;
  }
}

@media screen and (min-width: 766px) {
  //.navbar-box{
  //  display: none;
  //}
  .navbar {
    display: none;
  }
}

</style>
