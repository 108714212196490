import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/main'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index'),
    meta: {title: '首页'}
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/signIn/index'),
    meta: {title: '登录'}
  },
  // {
  //   path: '/serach',
  //   name: 'serach',
  //   component: () => import('@/views/search/index'),
  //   meta: {title: '搜索'}
  // },
  // {
  //   path: '/fyxl',
  //   name: 'fyxl',
  //   component: () => import('@/views/fyxl/index'),
  //   meta: {title: '法音宣流'}
  // },
  {
    path: '/serach',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/search/index'),
        name: 'serach',
        meta: { title: '搜索', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/fyxl',
    component: Layout,
    hidden: true,
    index:3,
    children: [
      {
        path: 'index',
        component: () => import('@/views/fyxl/index'),
        name: 'index',
        meta: { title: '法音宣流', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/live',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/live/index'),
        name: 'index',
        meta: { title: '直播间', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/kjyy',
    component: Layout,
    hidden: true,
    index:1,
    children: [
      {
        path: 'index',
        component: () => import('@/views/kjyy/index'),
        name: 'index',
        meta: { title: '开卷有益', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/articleInfo',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/articleInfo/index'),
        name: 'index',
        meta: { title: '文章详情', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/q&a',
    component: Layout,
    hidden: true,
    index:4,

    children: [
      {
        path: 'index',
        component: () => import('@/views/q&a/index'),
        name: 'index',
        meta: { title: '答疑解惑', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/law',
    component: Layout,
    hidden: true,
    index:5,
    children: [
      {
        path: 'index',
        component: () => import('@/views/law/index'),
        name: 'index',
        meta: { title: '法务通知', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/me',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/me/index'),
        name: 'index',
        meta: { title: '个人中心', icon: 'el-icon-video-camera', noCache: true }
      }
    ]
  },
  {
    path: '/sdmc',
    component: Layout,
    hidden: true,
    index:6,

    children: [
      {
        path: 'index',
        component: () => import('@/views/sdmc/index'),
        name: 'index',
        meta: { title: '圣地名刹', icon: 'el-icon-video-camera', noCache: true }
      },
      {
        path: 'sdmcInfo',
        component: () => import('@/views/sdmcInfo/index'),
        name: 'index',
        meta: { title: '圣地名刹', icon: 'el-icon-video-camera', noCache: true }
      },
    ]
  },
  {
    path: '/material',
    component: Layout,
    hidden: true,
    index:2,
    children: [
      {
        path: 'index',
        component: () => import('@/views/material/index'),
        name: 'index',
        meta: { title: '资料下载', icon: 'el-icon-video-camera', noCache: true }
      },
      {
        path: 'classify',
        component: () => import('@/views/material/classify/index'),
        name: 'classify',
        meta: { title: '资料下载', icon: 'el-icon-video-camera', noCache: true }
      },
      {
        path: 'packages',
        component: () => import('@/views/material/classify/packages/index'),
        name: 'packages',
        meta: { title: '资料下载', icon: 'el-icon-video-camera', noCache: true }
      },
    ]
  },
  {
    path: '/noFound',
    name: 'noFound',
    component: () => import('@/views/noFound/index')
  },
  { path: '*', redirect: '/noFound', hidden: true }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
