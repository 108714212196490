/**
 * @Description: 页面布局
 * @author Sira
 * @date 2022/9/30
*/
<template>
  <div>
    <Navbar/>
    <transition name="fade">
      <router-view/>
    </transition>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/layout/components/Navbar";
import Footer from "@/layout/components/Footer";
import VScaleScreen from 'v-scale-screen'

export default {
  name: "main",
  components:{Footer, Navbar,VScaleScreen}
}
</script>

<style scoped>

</style>
